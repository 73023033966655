import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"problem-list"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Problems ")]),_c(VDivider,{staticClass:"mt-4"}),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.problemListTable,"options":_vm.options,"server-items-length":_vm.totalproblemListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event},"refetch-data":_vm.fetchProblems},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":("/apps/user/view/" + (item.user_id))}},[_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s(item.user.first_name + ' ' + item.user.last_name))])])]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize font-weight-semibold text--primary"},[_vm._v(_vm._s('AK-'+item.order_id))])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }